export const DEFAULT_BIA_DOMAIN = 'bia';

export const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const N_A = 'n/a';
export const FIELD_NOT_FOUND = '-';

export const ORDER_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
};

export const KWH = 'kWh';
export const MWH = 'MWh';
export const COMPOUND = 'compound';
export const KW = 'kW';
export const KG = 'kg';
export const KM = 'km';
