import { handleFetchError } from '@iso/lib/helpers/errorHandle';

const BASE_URL = process.env.REACT_APP_GATEWAY_URL;
export const fetchUnlock = async (connectorId, evseName) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/ocpp16/unlock-connector' +
    '?connectorId=' +
    connectorId +
    '&evseName=' +
    evseName;
  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    }
  } catch (e) {}
};

export const fetchReset = async (resetType, evseName) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url =
    BASE_URL +
    '/api/ocpp16/reset' +
    '?resetType=' +
    resetType +
    '&evseName=' +
    evseName;
  try {
    let response = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });
    const status = await response.status;
    if (status === 200) {
      let data = await response.json();
      return data;
    }
  } catch (e) {}
};

export const fetchFacilityFromOcpp = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/facility/uri/${facilityUri}`;

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchAllEvseByFacilityUri = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/evse/facility/uri/${facilityUri}`;

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchAllEvseStatusByFacilityUri = async facilityUri => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/status/facility/${facilityUri}`;

  try {
    let response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: bearer,
        'Content-Type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateChargerOcppToggles = async ({ evseUri, acceptOptiSetpoints, acceptLocalBalancerSetpoints, acceptAllAuthorizeRequests }) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/evse/uri/${evseUri}/toggle?acceptOptiSetpoints=${acceptOptiSetpoints}&acceptLocalBalancerSetpoints=${acceptLocalBalancerSetpoints}&acceptAllAuthorizeRequests=${acceptAllAuthorizeRequests}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateFacilityBalancerToggle = async (facilityUri, staticBalancer) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/facility/uri/${facilityUri}/toggle?staticBalancer=${staticBalancer}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateAllChargersOcppToggles = async ({ facilityUri, acceptOptiSetpoints, acceptLocalBalancerSetpoints, acceptAllAuthorizeRequests }) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  let url = `${BASE_URL}/api/manager/evse/facility/uri/${facilityUri}/toggle-all`;
  if (typeof acceptOptiSetpoints === "boolean") url += `?acceptOptiSetpoints=${acceptOptiSetpoints}`;
  if (typeof acceptLocalBalancerSetpoints === "boolean") url += `?acceptLocalBalancerSetpoints=${acceptLocalBalancerSetpoints}`;
  if (typeof acceptAllAuthorizeRequests === "boolean") url += `?acceptAllAuthorizeRequests=${acceptAllAuthorizeRequests}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    const status = response.status;
    if (status === 200) return response.status;

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};

export const updateChargerOcppBoxId = async ({ evseUri, boxId }) => {
  const bearer = 'Bearer ' + localStorage.getItem('auth-token');
  const url = `${BASE_URL}/api/manager/evse/box-id?username=${boxId}&evse-uri=${evseUri}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: bearer,
        'content-type': 'application/json',
      }),
    });

    const status = response.status;
    if (status === 200) return response.status;

    let data = await response.json();

    handleFetchError(response, data);

    return data;
  } catch (e) {
    throw e;
  }
};