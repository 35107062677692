import actions from './actions';

const initState = {
  selectedOwner: undefined,
  owners: [],
};

export default function reducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.SET_SELECTED_OWNER: {
      return {
        ...state,
        selectedOwner: action.data,
      };
    }

    case actions.SET_OWNERS: {
      return {
        ...state,
        owners: action.data,
      };
    }

    default:
      return state;
  }
}
