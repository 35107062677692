const actions = {
  SET_SELECTED_OWNER: 'SET_SELECTED_OWNER',
  SET_OWNERS: 'SET_OWNERS',

  setSelectedOwner: data => ({
    type: actions.SET_SELECTED_OWNER,
    data,
  }),

  setOwners: data => ({
    type: actions.SET_OWNERS,
    data,
  }),
};
export default actions;
